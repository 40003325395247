:root {
  /* --swiper-theme-color: #ffdf53 !important; */
  --swiper-pagination-color: #ffdf53 !important;
  --swiper-pagination-bullet-inactive-color: #d9d9d9 !important;
  --swiper-navigation-size: 44px;
}

.afacad {
  font-family: 'Afacad', sans-serif;
  font-optical-sizing: auto;
}

.roboto {
  font-family: 'Roboto Serif', serif;
  font-optical-sizing: auto;
}

.inter {
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
}

.read-more {
  color: #017dc0;
}

.swiper-pagination-bullet-active {
  background: var(--swiper-pagination-color, var(--swiper-theme-color));
}
.swiper-pagination-bullet {
  background: var(--swiper-pagination-bullet-inactive-color, #d9d9d9);
}
.swiper-pagination
  .swiper-pagination-bullets-dynamic
  .swiper-pagination-bullets
  .swiper-pagination-horizontal {
  margin-bottom: 5rem !important;
  top: 4rem !important;
}
.swiper-pagination-bullet
  .swiper-pagination-bullet-active
  .swiper-pagination-bullet-active-main {
  /* margin-bottom: 5rem !important;
   */
  top: 4rem !important;
}
.swiper-slide {
  height: 21rem !important;
}
.swiper-button-prev,
.swiper-button-next {
  top: var(--swiper-navigation-top-offset, 40%) !important;
}
@media (max-width: 767px) {
  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-size: 20px !important;
    /* color: #fff; */
  }
  .swiper-slide {
    height: 18rem !important;
  }
}
/* .progress {
  position: fixed;
  left: 0;
  right: 0;
  height: 5px;
  background: var(--accent);
  bottom: 100px;
} */

.parallax {
  overflow: hidden;
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax .scroller {
  display: inline-flex;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  width: 100vw;
}

.parallax2 {
  overflow: hidden;
  /* letter-spacing: -2px; */
  /* line-height: 0.8; */
  margin: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
}

.parallax2 .scroller {
  /* font-weight: 600; */
  /* text-transform: uppercase; */
  /* font-size: 64px; */
  display: flex;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  /* width: 100vw; */
}

.parallax2 span {
  display: block;
  margin-right: 16px;
}

.parallax span {
  display: inline-flex;
  margin-right: 2.63rem;
  align-items: center;
  gap: 2.63rem;
  flex-shrink: 0;
}
